import React, { useEffect } from "react";


import { navigate } from "gatsby";
import { connect } from "react-redux";
import { ContentContainer } from "../../components";
import { TabProps, TabsContainer } from "../../components/tabs";
import { RootState } from "../../state";
import { updateTabContainers } from "../../state/tabsReducer";
import OpsScreenList from "./opsScreenList";

interface Props {
  dispatch: Function;
  opsScreenTabs: RootState['tabsSection'];
  buyer?:string;
  vwStat?:string;
  status?:string;
}

export const TAB_CONTAINER = "ops-screen-tabs";

const OpsScreen = ({
  opsScreenTabs,
  dispatch,
  buyer,
  vwStat,
  status
}: Props) => {

  // const [tabFocus, setTabFocus] = useState<number>(0);
  // const [tabSet, setTabs] = useState<TabProps[]>([]);

  const handleTabChangeFocus = (props: {
    tabs: TabProps[];
    focus: number;
    permalink: string;
    tabsContainerId: string;
  }) => {
    if (props.permalink) navigate(props.permalink);
    dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        },
      })
    );
  };

  const handleCloseTab = (tabs: TabProps[], closedIndex: number, currentFocus: number) => {
    let nextFocus = closedIndex < currentFocus ? currentFocus - 1 : currentFocus;
    nextFocus = nextFocus < 0 ? 0 : (nextFocus >= tabs.length ? nextFocus - 1: nextFocus);

    dispatch(
      updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus:  nextFocus,
          tabs: tabs,
        },
      })
    );
  };

  useEffect(() => {
    if (!opsScreenTabs.tabContainers[TAB_CONTAINER]) {
      //Initialize Redux tabsContainer for reportss
      let initialTabContainerFocus = 0;
      let initialTabs: TabProps[] = [
        {
          title: "Ops Screen",
          tabId: "ops-screen-list",
          content: (
              <OpsScreenList buyer={buyer} vwStat={vwStat} status={status} />),
          permalink: `/`,
        },
      ];

      const initialReportsTabContainer = {
        [TAB_CONTAINER]: {
          tabFocus: initialTabContainerFocus,
          tabs: initialTabs,
        },
      };

      dispatch(updateTabContainers(initialReportsTabContainer));

    } else if(opsScreenTabs.tabContainers[TAB_CONTAINER].tabs.length === 1){
      // raise indicator that we are on ops-screen
      // toogle marker opsScreenTabs.tsx @SAA-2369
      localStorage.setItem("lastPage", "ops");
    }
    
  }, [opsScreenTabs]);

  useEffect(() => {
    return () => {
      // clear indicator that we are on ops-screen
      // toogle marker opsScreenListFilters.tsx @SAA-2369
      localStorage.setItem("lastPage", "");
    }
  }, []);

  return (
    <ContentContainer>
      <TabsContainer
        tabs={opsScreenTabs.tabContainers[TAB_CONTAINER]?.tabs || []}
        onCloseTab={handleCloseTab}
        tabFocus={opsScreenTabs.tabContainers[TAB_CONTAINER]?.tabFocus || 0}
        onChange={handleTabChangeFocus}
        tabsContainerId={TAB_CONTAINER}
      />
    </ContentContainer>
  );
};

export default connect(
  (state: RootState) => ({
    opsScreenTabs: state.tabsSection,
  }),
  null
)(OpsScreen);
