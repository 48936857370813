import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  createStyles, Grid, Link, makeStyles, TableCell,
  TableFooter,
  TableRow,
  TabsProps,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { MUIDataTableColumnDef, MUIDataTableColumnState, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import "regenerator-runtime/runtime";
import { GET_BUYERS } from "../../common/models/buyers";
import { GET_OPS_SCREEN } from "../../common/models/opsScreen";
import { GetBuyers } from "../../common/models/types/GetBuyers";
import {
  GetOpsScreen,
  GetOpsScreen_LDPIngestQueryGroup_GetOpsScreen
} from "../../common/models/types/GetOpsScreen";
import { GetSubVertical } from "../../common/models/types/GetSubVertical";
import { GetVertical } from "../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import { flattenObject } from "../../common/utils";
import { getTimezoneSettingLongDesc, withinPast } from "../../common/utils/date";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";
import { sortNumber } from "../../common/utils/sort/mui-data-column-sort";
import { queryLoad } from "../../components";
import { acceptedRateFilter, buyerFilter, leadsSoldFilter, pingAverageFilter, pingCapFilter, pingFilter, postAverageFilter, postCapFilter, postRejectedFilter, revenueFilter, rptFilter } from "../../components/customMuiDatatableFilter";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { tabExist, TabProps } from "../../components/tabs";
import { EmailCharBlockers, eventTracker as tracker } from "../../components/tracker";
import { RootState } from "../../state";
import { DataCacheState, updateBuyers, updateSubVerticals, updateVerticals } from "../../state/dataCacheReducer";
import {
  opsScreenSearchStateProps,
  saveOpsScreenData
} from "../../state/opsScreenReducer";
import { tabContainerStateProps, updateTabContainers } from "../../state/tabsReducer";
import { OpsScreenDisposition } from "./opsScreenDisposition";
import OpsScreenListFilters from "./opsScreenListFilters";
import { TAB_CONTAINER } from "./opsScreenTabs";
import { VwPingStatsModalTab } from "./vwPingStatsModalTab";
import { DateTime } from "luxon";
import { OpsScreenCapacity } from "./opsScreenCapacity";
import { parse } from "date-fns";
import { toast } from "react-toastify";
import { isRoleExternalAccountManager } from "../../common/utils/roles";
import { OpsScreenRejectedFilterList } from "./opsScreenRejectedFilterList";

interface Props {
  dispatch: Function;
  opsScreenTabs: RootState['tabsSection']; //we need this to spawn opened tabs from here going to main page
  opsScreenSearch: opsScreenSearchStateProps;
  entityDataCache?: DataCacheState;
  buyer?: string; //buyer to opened tab to, if present
  vwStat?: string;
  status?: string;  
  loggedInUser?: any;
}

interface SelectFilterProps {
  name: string, 
  options: Array<string>,
  filterList: any, 
  onChange: Function, 
  index: number, 
  column: any,  
}

type detailType = "ping" | "post" | "pingfilter" | "postfilter" | "pingcapacity" | "postcapacity" | "pingdefault" | "postdefault";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    contentBody: {
      width: "100%",
    },
    input: {
      backgroundColor: "#FFFFFF",
    },
    linkTypo: {
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    linkTypoBuyer: {
      maxWidth: "200px",
      [theme.breakpoints.down(960)]: {
        maxWidth: "100%",
      },
    },
    linkTypoAM: {
      maxWidth: "90px",
      [theme.breakpoints.down(960)]: {
        maxWidth: "100%",
      },
    },
    totalOrAveFooterCell: {
      fontWeight: "bold",
      color: "#000000",
      fontSize: "1em",
      "& .preLabel": {
        color: "#787878",
      },
    },

    rightBorderSeparator: {
      borderRight: "2px solid rgba(224, 224, 224, 1)",
    },

    leftBorderSeparator: {
      borderLeft: "2px solid rgba(224, 224, 224, 1)",
    },
  })
);

const OpsScreenList = ({
  dispatch,
  opsScreenTabs,
  opsScreenSearch,
  entityDataCache, // from redux
  buyer,
  vwStat,
  status,
  loggedInUser,
}: Props) => {
  const googleUserMeta = GoogleUserMeta();
  const isUserExternalAccountManager = isRoleExternalAccountManager(loggedInUser);  

  const [
    getOpsScreen,
    { data: opsScreenData, called, error, loading: opsScreenLoading, refetch },
  ] = useLazyQuery<GetOpsScreen>(GET_OPS_SCREEN, {
    fetchPolicy: "no-cache",
  });

  const [
    getAllBuyers,
    { data: buyersData, error: buyersError, loading: buyersLoading },
  ] = useLazyQuery<GetBuyers>(GET_BUYERS);

  const [
    getAllVertical,
    { data: verticalData, error: verticalError, loading: verticalLoading },
  ] = useLazyQuery<GetVertical>(GET_VERTICAL);

  const [
    getAllSubVertical,
    {
      data: subVerticalData,
      error: subVerticalError,
      loading: subVerticalLoading,
    },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);

  const classes = useStyles();

  useEffect(() => {
    localStorage && localStorage.setItem(TAB_CONTAINER, JSON.stringify( opsScreenTabs.tabContainers[TAB_CONTAINER].tabs.map(tab => tab.tabId) ));
  }, [opsScreenTabs]);

  const [idNameVerticalMap, setIdNameVerticalMap] = useState<{
    [id: number]: string;
  }>({});

  const [idNameSubVerticalMap, setIdNameSubVerticalMap] = useState<{
    [id: number]: string;
  }>({});

  const [loading, setLoading] = useState<boolean>(true);
  const [focusBuyer, setFocusBuyer] = useState("");


  /** INITIALIZE fetch all buyers, verticals and sub verticals */
  useEffect(() => {
    if(!entityDataCache?.Buyers || (entityDataCache?.Buyers && !withinPast(entityDataCache?.Buyers.time, { days: 1 }))){
      getAllBuyers();
    }

    if(!entityDataCache?.ActiveVerticals || (entityDataCache?.ActiveVerticals && !withinPast(entityDataCache?.ActiveVerticals.time, { days: 1 }))){
      getAllVertical({
        variables: {
          where: `IsActive = true`,
        },
      });
    }
    
    if(!entityDataCache?.ActiveSubVerticals || (entityDataCache?.ActiveSubVerticals && !withinPast(entityDataCache?.ActiveSubVerticals.time, { days: 1 }))){
      getAllSubVertical({
        variables: {
          where: `IsActive = true`,
        },
      });
    }
  }, []);

  useEffect(() => {
    if(buyersData && !buyersError){
      dispatch && dispatch(
        updateBuyers(buyersData?.LDPConfigQueryGroup?.Buyer || [])
      );
    }
  }, [ buyersData, buyersError ]);

  useEffect(() => {
    if (verticalData && !verticalError) {
      dispatch && dispatch(
        updateVerticals(verticalData?.LDPConfigQueryGroup?.Vertical || [])
      );
    }
  }, [verticalData, verticalError]);

  useEffect(() => {
    if (verticalData) {
      setIdNameVerticalMap(
        Object.assign(
          {},
          ...(entityDataCache?.ActiveVerticals?.data?.map((entry) => ({
            [entry?.VerticalId]: entry?.VerticalName,
          })) || [])
        )
      );
    }
  }, [entityDataCache?.ActiveVerticals?.data]);

  useEffect(() => {
    if (subVerticalData && !subVerticalError) {
      dispatch && dispatch(
        updateSubVerticals(subVerticalData?.LDPConfigQueryGroup?.SubVertical || [])
      );
    }
  }, [subVerticalData, subVerticalError]);

  useEffect(() => {
    if (subVerticalData) {
      setIdNameSubVerticalMap(
        Object.assign(
          {},
          ...(entityDataCache?.ActiveSubVerticals?.data?.map(
            (entry) => ({ [entry?.SubVerticalId]: entry?.SubVerticalName })
          ) || [])
        )
      );
    }
  }, [entityDataCache?.ActiveSubVerticals?.data]);


  const handleCloseTab = (tabs: TabProps[], closedIndex: number, currentFocus: number) => {            
    let nextFocus = closedIndex < currentFocus ? currentFocus - 1 : currentFocus;
    nextFocus = nextFocus < 0 ? 0 : (nextFocus >= tabs.length ? nextFocus - 1: nextFocus);

    dispatch(
      updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus: nextFocus,
          tabs: tabs,
        },
      })
    );    
  };

  const spawnPingPostTab = (
    [BuyerName, AccountManagerName]: any[],
    pingOrPost: detailType,
    isSuccess: boolean | "both" = "both"
  ) => {
    if (isUserExternalAccountManager)
      return toast.error("Sorry you do not have access for this action right now!");

    const buyerTabId = BuyerName.trim().toLowerCase().replaceAll(/\s/g, "_");

    const tabContent = `${
      isSuccess === "both" ? "" : isSuccess ? "successful" : "rejected"
    }${pingOrPost === "ping" ? "-pings" : (pingOrPost === "post" ? "-posts" : `-${pingOrPost}s`) }`;

    const tabLinkId = `${
      isSuccess === "both" ? "" : isSuccess ? "/successful" : "/rejected"
    }${pingOrPost === "ping" ? "/pings" : (pingOrPost === "post" ? "/posts" : `/${pingOrPost}s`) }`;

    const tabId = `ops-screen-list-${buyerTabId}-${tabContent}`;

    const lastKnownTabs: string[] = localStorage && JSON.parse(localStorage.getItem(TAB_CONTAINER) || '["ops-screen-list"]' ) || ["ops-screen-list"];
    const tabsCleanedUp = opsScreenTabs.tabContainers[TAB_CONTAINER].tabs.filter(tab => lastKnownTabs.includes(tab.tabId));

    const tabExists = tabExist(
      tabsCleanedUp,
      tabId
    );

    if (tabExists > 0) {
      const tabs = tabsCleanedUp;
      const tabInstance = tabs.splice(tabExists, 1);
      const updatedTabs = {
        [TAB_CONTAINER]: {
          tabs: [
            ...tabs.slice(0, 1),
            tabInstance[0],
            ...tabs.slice(1),
          ],
          tabFocus: 1,
        },
      };

      dispatch(updateTabContainers(updatedTabs));
    } else {
      const tabProps = {
        startDate: opsScreenSearch.startDate,
        endDate: opsScreenSearch.endDate,
        buyer: BuyerName,
        accountManagerName: AccountManagerName,
        verticalId: opsScreenSearch?.verticalId || 1,
        subVerticalId: opsScreenSearch?.subVerticalId || 1,
        reportType: pingOrPost,
        isSuccess,
        verticalNames: idNameVerticalMap,
        subVerticalNames: idNameSubVerticalMap,
        fetchEDW: opsScreenSearch?.fetchEDW || false,
      };
      
      let content = <VwPingStatsModalTab {...tabProps} />;

      if (
        !isSuccess
      ) {
        switch (pingOrPost) {
          case "pingcapacity":
          case "postcapacity":
            content = (
              <OpsScreenCapacity 
                params={{
                  startDate: tabProps.startDate,
                  endDate: tabProps.endDate,
                  accountManagerName: tabProps.accountManagerName,
                  buyerName: tabProps.buyer,
                  verticalId: opsScreenSearch?.verticalId?.toString() ?? "",
                  subVerticalId: opsScreenSearch?.subVerticalId?.toString() ?? "",
                  isSuccess: isSuccess ? 1 : 0,
                  isPost: pingOrPost === "postcapacity" ? true : false,
                }}
                fetchEDW={tabProps.fetchEDW}
              />
            );
          break;
          case "pingfilter":
          case "postfilter":
            content = (
              <OpsScreenRejectedFilterList
                params={{
                  startDate: tabProps.startDate,
                  endDate: tabProps.endDate,
                  accountManagerName: tabProps.accountManagerName,
                  buyerName: tabProps.buyer,
                  verticalId: opsScreenSearch?.verticalId?.toString() ?? "",
                  subVerticalId: opsScreenSearch?.subVerticalId?.toString() ?? "",
                  // isSuccess: isSuccess ? 1 : 0,
                  isPost: pingOrPost === "postfilter" ? true : false,
                }}
                fetchEDW={tabProps.fetchEDW}
              />
            );
          break;
          default:
            content = (
              <OpsScreenDisposition
                params={{
                  startDate: tabProps.startDate,
                  endDate: tabProps.endDate,
                  accountManagerName: tabProps.accountManagerName,
                  buyerName: tabProps.buyer,
                  verticalId: opsScreenSearch?.verticalId?.toString() ?? "",
                  subVerticalId: opsScreenSearch?.subVerticalId?.toString() ?? "",
                  isSuccess: isSuccess ? 1 : 0,
                  isPost: pingOrPost === "post" || pingOrPost === "postdefault" ? true : false,
                }}
                fetchEDW={tabProps.fetchEDW}
                showDefault={pingOrPost === "pingdefault" || pingOrPost === "postdefault"}
              />
            );
        }
      }

      const opsScreenTab: TabProps = {
        title: `${BuyerName}${
          isSuccess === "both" ? " " : isSuccess ? " Successful" : " Rejected"
        } ${
          pingOrPost === "ping"
            ? "Pings"
            : pingOrPost === "post"
            ? "Posts"
            : pingOrPost === "pingfilter"
            ? "Ping Filter"
            : pingOrPost === "postfilter"
            ? "Post Filter"
            : pingOrPost === "pingcapacity"
            ? "Ping Capacity"
            : pingOrPost === "pingdefault"
            ? "Ping Default"
            : pingOrPost === "postdefault"
            ? "Post Default"
            : "Post Capacity"
        }`,
        tabId,
        content,
        closeTab: handleCloseTab,
      };

      let currentTabs: TabProps[] = tabsCleanedUp;

      const mainTab = currentTabs.shift();

      if (currentTabs.length > 0) {        
        currentTabs = currentTabs.filter(({ tabId: _tabId }: any) => _tabId !== tabId); 
      }

      currentTabs.unshift(opsScreenTab);
      mainTab && currentTabs.unshift(mainTab);

      const updatedTabs = {
        [TAB_CONTAINER]: {
          tabs: [
            ...currentTabs,
          ],
          tabFocus: 1,
        },
      };

      dispatch(updateTabContainers(updatedTabs));
    }
  };

  const openBuyerTab = (
    tableMeta: any,
    pingOrPost: detailType,
    isSuccess: boolean | "both" = "both"
  ) => {
    const rowData = tableMeta.rowData;

    const buyerData = {
      Buyer: rowData[0], 
      AccountManager:  rowData[1], 
      Sold:  rowData[2], 
      Posts:  rowData[3], 
      PostAccepted:  rowData[4], 
      PostRejected:  rowData[5], 
      PostCapacityRejected:  rowData[6], 
      PostFilterRejected: rowData[7], 
      PostAcceptedRate: rowData[8], 
      AveragePostTimeMs: rowData[9], 
      TotalRevenue: rowData[10], 
      RPT: rowData[11], 
      Pings: rowData[12], 
      PingAccepted: rowData[13], 
      PingRejected: rowData[14], 
      PingCapacityRejected: rowData[15], 
      PingFilterRejected: rowData[16], 
      PingAcceptedRate: rowData[17], 
      AveragePingTimeMs: rowData[18],
      PostDefaultRejected: rowData[18], 
      PingDefaultRejected: rowData[19],
    };

    tracker({
      name: "Column Selected/Tab Opened Off Ops Screen",
      caption: "Track Ops Screen Column Selected/Tab Opened Off",
      values: {         
        ...buyerData,
        email: googleUserMeta?.email?.replace(/\@|\./g, it => EmailCharBlockers[it]) ?? null 
      } 
    });

    spawnPingPostTab(tableMeta.rowData, pingOrPost, isSuccess);
  };

  useEffect(() => {
    //focus buyer tab if tab exists
    if (focusBuyer && focusBuyer !== "") {
      //find buyer details and load the tab
      const tabIndex: number = tabExist(
        opsScreenTabs.tabContainers[TAB_CONTAINER].tabs,
        `ops-screen-list-${buyer}${
          status && status !== "" ? `-${status}` : ""
        }-${vwStat}`
      );

      if (tabIndex > 0) {
        dispatch(
          updateTabContainers({
            [TAB_CONTAINER]: {
              tabs: opsScreenTabs.tabContainers[TAB_CONTAINER].tabs,
              tabFocus: tabIndex,
            }
          })
        );
      }
    }
  }, [focusBuyer]);

  useEffect(() => {
    //focus buyer tab after loading list
    if (
      opsScreenData &&
      !error &&
      focusBuyer &&
      !!opsScreenData?.LDPIngestQueryGroup?.GetOpsScreen
    ) {
      const opsScreenData =
        opsScreenData?.LDPIngestQueryGroup?.GetOpsScreen || [];
      for (let i = 0; i < opsScreenData.length; i++) {
        const buyerName = opsScreenData[i].BuyerName.trim()
          .toLowerCase()
          .replaceAll(/\s/g, "_");
        if (buyerName === focusBuyer) {
          spawnPingPostTab(
            opsScreenData[i],
            vwStat,
            status === "successful" ? true : status !== "both" ? false : "both"
          );
          break;
        }
      }
    }
  }, [opsScreenData, focusBuyer]);

  useEffect(() => {
    if (!error && opsScreenData) {
      dispatch && dispatch(saveOpsScreenData(opsScreenData));
    }
  }, [opsScreenData, error]);

  const [lastDateSet, setLastDateSet] = useState<string>("");

  /** prepare gtag date range initial value */
  useEffect(() => {
    if (
      lastDateSet === "" &&
      opsScreenSearch?.startDate &&
      opsScreenSearch?.endDate
    ) {
      setLastDateSet(
        `${opsScreenSearch?.startDate}&&${opsScreenSearch?.endDate}`
      );
    }
  }, [opsScreenSearch]);

  const [lastLoadId, setLastLoadId] = useState<string>("");

  /** getOpScreen query only when opsScreenSearch.opsScreenSearchId (redux state) is updated */
  useEffect(() => {
    if (lastLoadId === "") {
      setLastLoadId(opsScreenSearch?.opsScreenSearchId || "");
      return;
    }

    if (
      opsScreenSearch?.startDate &&
      opsScreenSearch?.endDate &&
      opsScreenSearch?.verticalId &&
      opsScreenSearch?.subVerticalId
    ) {
      const newDateSet = `${opsScreenSearch?.startDate}&&${opsScreenSearch?.endDate}`;

      if (lastDateSet !== newDateSet) {
        setLastDateSet(newDateSet);
        //gtag();
      }

      // discard cached data
      dispatch && dispatch(saveOpsScreenData(undefined));

      //for StartDate less (1 day prod/ 7 days stage) from current date fetch from EDW else fetch from ODS
      //getTimezoneSetting()

      // const currentDate = DateTime.local().toFormat("MMM dd, yyyy hh:mm:ss a")
      // const odsStartDate = 
      //process.env.NODE_ENV === "production"
      //get current UTC
      // request new data
      getOpsScreen({
        variables: {
          startDate: opsScreenSearch.startDate,
          endDate: opsScreenSearch.endDate,
          verticalId: opsScreenSearch.verticalId,
          subVerticalId: opsScreenSearch.subVerticalId,
          fetchEDW: opsScreenSearch.fetchEDW,
          buyerName: opsScreenSearch.buyerName,
          timezone: getTimezoneSettingLongDesc()
        },
      });
    }
  }, [opsScreenSearch?.opsScreenSearchId]);

  const [muiTableData, setMuiTableData] = useState<any>();
  useEffect(() => {
    setMuiTableData(
      (opsScreenSearch?.lastLoadedData?.LDPIngestQueryGroup?.GetOpsScreen || [])
        .filter(dataBuyerFilter)
        .map((obj: GetOpsScreen_LDPIngestQueryGroup_GetOpsScreen | null) =>
          flattenObject(obj)
        )
    );
  }, [opsScreenSearch?.lastLoadedData]);

  const dataBuyerFilter = (
    row: GetOpsScreen_LDPIngestQueryGroup_GetOpsScreen | null
  ) => {
    if (opsScreenSearch?.buyerName) {
      return (
        row?.BuyerName?.toLowerCase() ===
        opsScreenSearch?.buyerName.toLowerCase()
      );
    } else {
      return true;
    }
  };

  const columnsMUI:MUIDataTableColumnDef[] = [
    {
      name: "BuyerName",
      label: "Buyer",
      options: {
        viewColumns: true,        
        setCellProps: (cv, ri, ci) => ({ style: { 
          whiteSpace: "nowrap",
          position: "sticky",
          left: "0",
          zIndex: 100,
          background: ((ri + 1) % 2) == 0 ? 'white' : '#E0F1EF',
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
        } }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <HtmlTooltip
            title={<Typography color="inherit">{value}</Typography>}
            placement="top"
          >
            <span className={`${classes.linkTypo} ${classes.linkTypoBuyer}`}>
              {value}
            </span>
          </HtmlTooltip>
        ),
        ...buyerFilter,
      },
    },
    {
      name: "AccountManagerName",
      label: "Account Manager",
      options: {
        viewColumns: true,
        display: false,
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value: any, tableMeta: any) => (
          <HtmlTooltip
            title={<Typography color="inherit">{value}</Typography>}
            placement="top"
          >
            <span className={`${classes.linkTypo} ${classes.linkTypoAM}`}>
              {value}
            </span>
          </HtmlTooltip>
        ),
      },
    },
    {
      name: "Sold",
      label: "Sold",
      options: {
        viewColumns: true,        
        sortCompare: sortNumber,
        ...leadsSoldFilter, 
      },
    },
    {
      name: "Posts",
      label: "Posts",
      options: {
        viewColumns: true,        
        customBodyRender: (value: any, tableMeta: any) => (
          <Link
            href="#"
            onClick={() => {
              openBuyerTab(tableMeta, "post");
            }}
          >
            {value}
          </Link>
        ),
        sortCompare: sortNumber,
        ...leadsSoldFilter,
      }
    },
    {
      name: "PostAccepted",
      label: "Post Accepted",
      options: {
        viewColumns: true,        
        customBodyRender: (value: any, tableMeta: any) =>
          value != 0 ? (
            <Link
              href="#"
              onClick={() => {
                openBuyerTab(tableMeta, "post", true);
              }}
            >
              {value}
            </Link>
          ) : (
            value
          ),
        sortCompare: sortNumber,
        ...leadsSoldFilter,           
      },
    },
    {
      name: "PostRejected",
      label: "Post Rejected",
      options: {
        viewColumns: true,        
        customBodyRender: (value: any, tableMeta: any) =>
          value != 0 ? (
            <Link
              href="#"
              onClick={() => {
                openBuyerTab(tableMeta, "post", false);
              }}
            >
              {value}
            </Link>
          ) : (
            value
          ),
        ...leadsSoldFilter,  
      },
    },
    {
      name: "PostCapacityRejected",
      label: "Post Capacity Rejected",
      options: {
        viewColumns: true,
        display: false,
        ...postCapFilter,
        customBodyRender: (value: any, tableMeta: any) =>
          value != 0 ? (
            <Link
              href="#"
              onClick={() => {
                openBuyerTab(tableMeta, "postcapacity", false);
              }}
            >
              {value}
            </Link>
          ) : (
            value
          ),
      },
    },
    {
      name: "PostFilterRejected",
      label: "Post Filter Rejected",
      options: {
        viewColumns: true,
        display: false,  
        customBodyRender: (value: any, tableMeta: any) =>
          value != 0 ? (
            <Link
              href="#"
              onClick={() => {
                openBuyerTab(tableMeta, "postfilter", false);
              }}
            >
              {value}
            </Link>
          ) : (
            value
          ),
        ...postRejectedFilter,
      },
    },
    {
      name: "PostDefaultRejected",
      label: "Post Default Rejected",
      options: {
        viewColumns: true,
        display: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return value != 0 ? (
            <Link
              href="#"
              onClick={() => {
                openBuyerTab(tableMeta, "postdefault", false);
              }}
            >
              {value}
            </Link>
          ) : (
            value
          )
        },
      },
    },
    {
      name: "PostAcceptedRate",
      label: "Post Accepted Rate",
      options: {
        viewColumns: true,
        customBodyRender: (value: any, tableMeta: any) => {
          const v = Number(value);
          return v < 10 ? `0${v.toFixed(2)} %` : `${v.toFixed(2)} %`;
        },
        ...acceptedRateFilter,
      },
    },
    {
      name: "AveragePostTimeMs",
      label: "Average Post TimeMS",
      options: {
        viewColumns: true,        
        sortCompare: sortNumber,
        ...postAverageFilter,
      },
    },
    {
      name: "TotalRevenue",
      label: "Total Revenue",
      options: {
        viewColumns: false,
        customBodyRender: (value: any, tableMeta: any) => `$${Number(value).toFixed(2)}`,
        setCellProps: (value: any) => {
          return {
            style: {
              borderLeft: "2px solid rgba(224, 224, 224, 1)",
            },
          };
        },
        sortCompare: sortNumber,
        ...revenueFilter,
      },
    },
    {
      name: "RPT",
      label: "RPT",
      options: {
        viewColumns: false,
        customBodyRender: (value: any, tableMeta: any) => `$${Number(value).toFixed(2)}`,
        setCellProps: (value: any) => {
          return {
            style: {
              borderRight: "2px solid rgba(224, 224, 224, 1)",
            },
          };
        },
        sortCompare: sortNumber,
        ...rptFilter,
      },
    },

    {
      name: "Pings",
      label: "Pings",
      options: {
        viewColumns: true,        
        customBodyRender: (value: any, tableMeta: any) => (
          <Link
            href="#"
            onClick={() => {
              openBuyerTab(tableMeta, "ping");
            }}
          >
            {value}
          </Link>
        ),
        sortCompare: sortNumber,
        ...pingFilter,
      },
    },
    {
      name: "PingAccepted",
      label: "Ping Accepted",
      options: {
        viewColumns: true,        
        customBodyRender: (value: any, tableMeta: any) =>
          value != 0 ? (
            <Link
              href="#"
              onClick={() => {
                openBuyerTab(tableMeta, "ping", true);
              }}
            >
              {value}
            </Link>
          ) : (
            value
          ),
        sortCompare: sortNumber,
        ...pingCapFilter, 
      },
    },
    {
      name: "PingRejected",
      label: "Ping Rejected",
      options: {
        viewColumns: true,        
        customBodyRender: (value: any, tableMeta: any) =>
          value != 0 ? (
            <Link
              href="#"
              onClick={() => {
                openBuyerTab(tableMeta, "ping", false);
              }}
            >
              {value}
            </Link>
          ) : (
            value
          ),
        sortCompare: sortNumber,
        ...pingFilter,
      },
    },
    {
      name: "PingCapacityRejected",
      label: "Ping Capacity Rejected",
      options: {
        viewColumns: true,
        display: false,
        sortCompare: sortNumber,
        ...pingCapFilter, 
        customBodyRender: (value: any, tableMeta: any) =>
          value != 0 ? (
            <Link
              href="#"
              onClick={() => {
                openBuyerTab(tableMeta, "pingcapacity", false);
              }}
            >
              {value}
            </Link>
          ) : (
            value
          ),
      },
    },
    {
      name: "PingFilterRejected",
      label: "Ping Filter Rejected",
      options: {
        viewColumns: true,
        display: false,
        customBodyRender: (value: any, tableMeta: any) =>
          value != 0 ? (
            <Link
              href="#"
              onClick={() => {
                openBuyerTab(tableMeta, "pingfilter", false);
              }}
            >
              {value}
            </Link>
          ) : (
            value
          ),
        sortCompare: sortNumber,
        ...pingFilter, 
      },
    },
    {
      name: "PingDefaultRejected",
      label: "Ping Default Rejected",
      options: {
        viewColumns: true,
        display: false,
        customBodyRender: (value: any, tableMeta: any) =>
          value != 0 ? (
            <Link
              href="#"
              onClick={() => {
                openBuyerTab(tableMeta, "pingdefault", false);
              }}
            >
              {value}
            </Link>
          ) : (
            value
          ),
        sortCompare: sortNumber,
      },
    },
    {
      name: "PingAcceptedRate",
      label: "Ping Accepted Rate",
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          const v = Number(value);
          return v < 10 ? `0${v.toFixed(2)} %` : `${v.toFixed(2)} %`;
        },
        viewColumns: true,        
        sortCompare: sortNumber,
        ...acceptedRateFilter,
      },
    },
    {
      name: "AveragePingTimeMs",
      label: "Average Ping TimeMS",
      options: {
        viewColumns: true,        
        sortCompare: sortNumber,
        ...pingAverageFilter,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.totalOrAveFooterCell]: true,
  });

  const footerClassesWithLeftBorder = clsx({
    [classes.totalOrAveFooterCell]: true,
    [classes.leftBorderSeparator]: true,
  });

  const footerClassesWithRightBorder = clsx({
    [classes.totalOrAveFooterCell]: true,
    [classes.rightBorderSeparator]: true,
  });

  const parseColumnToNumber = (value: any) => {
    if (typeof value === 'number')
      return value;

    let val = value ?? '';
    if (typeof val === 'object')  
      val = value?.props?.children?.toString();

      
    return Number(val?.replace(',', '').replace('%', '').replace('$', '').replace(' ', ''));
  }

  const getFooterCell = (columns: any[], columnName: string, index: number, rows: any) => {    
    if (columnName === "BuyerName")
      return <TableCell key={index}>&nbsp;</TableCell>;      

    let total = rows.reduce((total: number, {data}: any) => total + parseColumnToNumber(data[index]), 0);

    let isShowAvgLabel = false,
        isDollar = false, isPercent = true;
    if (["PostAcceptedRate", "AveragePostTimeMs", "PingAcceptedRate", "AveragePingTimeMs", "RPT"].includes(columnName))     
    {
      let countables = 0;
      columns.map(({name}: any, pos: number) => {
        if (name === columnName) {
          countables = rows.reduce((total: number, {data}: any) => total + (parseColumnToNumber(data[pos]) > 0 ? 1 : 0)  , 0);
        }
      });

      countables = countables < 1 ? 0 : countables;
      total = (total / countables).toFixed(2);      
      isShowAvgLabel = true;
    }                

    let styleClassName = footerClasses;
    if (["Pings", "TotalRevenue"].includes(columnName)) {
      styleClassName = footerClassesWithLeftBorder;
    }

    if (["AveragePostTimeMs", "RPT"].includes(columnName)) {
      styleClassName = footerClassesWithRightBorder;
    }

    if (["TotalRevenue", "RPT"].includes(columnName)) {
      isDollar = true;

      if(columnName === "TotalRevenue"){
        total = total.toFixed(2);
      }
    }

    isPercent = ["PostAcceptedRate", "PingAcceptedRate"].includes(columnName);

    return (
      <TableCell key={index} className={styleClassName}>        
        {isShowAvgLabel && (<span className="preLabel">Avg: </span>)}
        {isDollar && "$"}
        <NumberFormat
          value={total}
          displayType={"text"}
          thousandSeparator={true}
        />
        {isPercent && "%"}
      </TableCell>
    );
  };

  const options: MUIDataTableOptions = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "BuyerName",
      direction: "asc",
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    fixedHeader: true,
    fixedSelectColumn: true,
    downloadOptions: {
      filename: `opsScreen-vert#${opsScreenSearch?.verticalId}-subv#${opsScreenSearch?.subVerticalId}-${opsScreenSearch?.startDate}_${opsScreenSearch?.endDate}.csv`
    },
    customTableBodyFooterRender: function (opts) {      
      return (
        <TableFooter>
          <TableRow>
            {opts.selectableRows !== "none" ? <TableCell /> : null}
            {opts.columns.map((column: MUIDataTableColumnState, index: number) => {              
              return column.display === "true" ? getFooterCell(opts.columns, column.name, index, opts.data) : null              
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onDownload: (buildHead, buildBody, columns, data) => {     
      tracker({
          name: "Ops Screen Export button",
          caption: "Track Ops Screen Export",
          values: {         
            email: googleUserMeta?.email?.replace(/\@|\./g, it => EmailCharBlockers[it]) ?? null 
          } 
      });

      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    tableBodyHeight:'100%',
    tableBodyMaxHeight:'calc(100vh-64px)'
  };

  return (
    <Box className={classes.contentBody} >
      <OpsScreenListFilters
        setLoadingCallback={setLoading}
        buyersData={entityDataCache?.Buyers?.data || []}
        verticalData={entityDataCache?.ActiveVerticals?.data || []}
        subVerticalData={entityDataCache?.ActiveSubVerticals?.data || []}      
        googleUserMeta={googleUserMeta} 
      />
      <Box p={1}>
        {queryLoad([!!opsScreenLoading, !!loading], [error]) || (
            <LDPUIDataTable
              ldpTableId="ops-screen-list-ping-post-stats"
              restoreFilters={true}
              title={<Typography variant="h6">&nbsp;</Typography>}
              data={muiTableData}
              columns={columnsMUI}
              options={options}
            />
        )}
      </Box>
    </Box>
  );
};

export default connect(
  (state: RootState, ownProps) => ({
    opsScreenTabs: state.tabsSection,
    opsScreenSearch: state.opsScreenSearch,
    entityDataCache: state.entityDataCache,    
    loggedInUser: state.loggedInUser,
  }),
  null
)(OpsScreenList);
