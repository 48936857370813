import {
  Box,
  Button,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DateTime } from "luxon";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import "regenerator-runtime/runtime";
import { GetBuyers_LDPConfigQueryGroup_Buyer } from "../../common/models/types/getBuyers";
import { GetSubVertical_LDPConfigQueryGroup_SubVertical } from "../../common/models/types/GetSubVertical";
import { GetVertical_LDPConfigQueryGroup_Vertical } from "../../common/models/types/GetVertical";
import {
  dateToMomentTz,
  getTimezoneSetting,
  LDPAPIDateFormat,
} from "../../common/utils/date";
import { useConfirmation } from "../../components";
import { RootState } from "../../state";
import {
  opsScreenSearchStateProps,
  saveOpsScreenSearch,
} from "../../state/opsScreenReducer";

import { eventTracker as tracker } from '../../components/tracker';
import { GoogleUserMetaProp } from "../../common/utils/googleUserMeta";
import { TAB_CONTAINER } from "./opsScreenTabs";
import { updateTabContainers } from "../../state/tabsReducer";

interface Props {
  dispatch: Function;
  opsScreenSearch: RootState['opsScreenSearch'];
  opsScreenTabs: RootState['tabsSection'];
  setLoadingCallback: Function;
  buyersData?: (GetBuyers_LDPConfigQueryGroup_Buyer | null)[];
  verticalData?: (GetVertical_LDPConfigQueryGroup_Vertical | null)[];
  subVerticalData?: (GetSubVertical_LDPConfigQueryGroup_SubVertical | null)[];
  googleUserMeta?: GoogleUserMetaProp;
}

interface MappedSelectField {
  id: number;
  label: string;
  parent?: number;
  value?: string | null | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    input: {
      backgroundColor: "#FFFFFF",
    },
  })
);

const timezone = getTimezoneSetting();
const opsScreenProcTime = "US/Pacific";


const OpsScreenListFilters = ({
  dispatch, // from redux
  opsScreenSearch, // from redux
  opsScreenTabs,
  setLoadingCallback,
  buyersData,
  verticalData,
  subVerticalData,
  googleUserMeta
}: Props) => {
  

  const todayStart = () =>
    DateTime.local().setZone(timezone).set({ hour: 0, minute: 0, second: 0 });
  const todayEnd = () =>
    DateTime.local()
      .setZone(timezone)
      .set({ hour: 23, minute: 59, second: 59 });

  const classes = useStyles();

  /** use for displaying dates on components that doesnt support moment objects */
  const [startDateNative, setStartDateNative] = useState<Date>(
    todayStart().toJSDate()
  );
  const [endDateNative, setEndDateNative] = useState<Date>(
    todayEnd().toJSDate()
  );

  /* these are the actual dates that we use **/
  const [startDate, setStartDate] = useState<DateTime>(todayStart());
  const [endDate, setEndDate] = useState<DateTime>(todayEnd());

  const [mappedBuyers, setMappedBuyers] = useState<MappedSelectField[]>();
  const [selectedBuyer, setSelectedBuyer] = useState<MappedSelectField | null>(
    null
  );

  const [mappedVertical, setMappedVertical] = useState<MappedSelectField[]>();
  const [
    selectedVertical,
    setSelectedVertical,
  ] = useState<MappedSelectField | null>(null);

  const [mappedSubVertical, setMappedSubVertical] = useState<
    MappedSelectField[]
  >();
  const [
    selectedSubVertical,
    setSelectedSubVertical,
  ] = useState<MappedSelectField | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    errors,
    control,
  } = useForm();

  

  /** map buyers to dropdown */
  useEffect(() => {
    if (buyersData) {
      setMappedBuyers(
        buyersData?.map((buyer) => {
          return {
            id: buyer?.BuyerId,
            label: `${buyer?.BuyerName} - ${buyer?.BuyerId}`,
            value: buyer?.BuyerName || null,
          };
        })
      );
    }
  }, [buyersData]);

  /** set selectedBuyer once buyer dropdown is ready*/
  useEffect(() => {
    if (mappedBuyers) {
      let _selectedBuyer: any = null;
      if (opsScreenSearch?.buyerName) {
        _selectedBuyer = mappedBuyers?.find(
          (x) => x.value === opsScreenSearch.buyerName
        );
      }
      setSelectedBuyer(_selectedBuyer);
    }
  }, [mappedBuyers]);

  /** map verticals to dropdown */
  useEffect(() => {
    if (verticalData) {
      setMappedVertical(
        verticalData?.map((x) => {
          return {
            id: x?.VerticalId,
            label: `${x?.VerticalName} - ${x?.VerticalId}`,
          };
        })
      );
    }
  }, [verticalData]);

  /** set selectedVertical once vertical dropdown is ready*/
  useEffect(() => {
    if (mappedVertical) {
      let _selectedVertical: any =
        (mappedVertical && mappedVertical[0]) || null;
      if (opsScreenSearch?.verticalId) {
        _selectedVertical = mappedVertical?.find(
          (x) => x.id === opsScreenSearch?.verticalId
        );
      }
      setSelectedVertical(_selectedVertical);
    }
  }, [mappedVertical]);

  /** map sub verticals to dropdown depending on selectedVertical*/
  useEffect(() => {
    if (subVerticalData) {
      if (selectedVertical) {
        updateSubVerticalOptions(selectedVertical).sort((a, b) => a.id - b.id);
      }
    }
  }, [subVerticalData, selectedVertical]);

  /** set selectedSubVertical once sub vertical dropdown is ready*/
  useEffect(() => {
    if (mappedSubVertical) {
      let _selectedSubVertical: any =
        (mappedSubVertical && mappedSubVertical[0]) || null;

      if (opsScreenSearch?.subVerticalId) {
        _selectedSubVertical = mappedSubVertical?.find(
          (x) => x.id === opsScreenSearch?.subVerticalId
        );
      }
      setSelectedSubVertical(_selectedSubVertical || mappedSubVertical[0] || null);
    }
  }, [mappedSubVertical]);

  const updateSubVerticalOptions = (vertical: MappedSelectField) => {
    const subVerticalList =
      subVerticalData?.map((item: any) => ({
        id: item?.SubVerticalId,
        label: item?.SubVerticalName,
        parent: item?.VerticalId,
      })) || [];

    const options =
      subVerticalList.filter((item: any) => item?.parent === vertical.id) ?? [];
    setMappedSubVertical(options);

    return options;
  };

  /** Update Filter values in Redux opsScreeenSearch */
  const handleUpdateOpsScreenFilters = (forceLoad: boolean = false) => {
    //only update if there is a change in value
    if (!loading || forceLoad) {
      const startDateTzFmt = startDate?.toFormat(LDPAPIDateFormat);
      const endDateTzFmt = endDate?.toFormat(LDPAPIDateFormat);
      const edwDaysMargin = Number(process.env.FETCH_EDW_DAYS_CONDITION) || 5;
      const odsStartDateTzFmt = DateTime.now().setZone(timezone).minus({days: edwDaysMargin}).toFormat(LDPAPIDateFormat);
      const fetchEDW = startDateTzFmt < odsStartDateTzFmt ? true : false;

      dispatch &&
        dispatch(
          saveOpsScreenSearch({
            startDate: startDateTzFmt,
            endDate: endDateTzFmt,
            buyerName: selectedBuyer?.value || "",
            verticalId: selectedVertical?.id || 1,
            subVerticalId: selectedSubVertical?.id || 1,
            timeSelectionMode: pastTime,
            opsScreenSearchId: `${DateTime.now().toMillis()}`,
            fetchEDW: fetchEDW,
          })
        );
    }
  };

  /**
   * set Loading to false once
   * - Vertical and Buyers is loaded and mapped
   * - filters is loaded and mapped
   * */
  useEffect(() => {
    if (mappedVertical && mappedBuyers) {
      setLoadingCallback(false);
    }
  }, [mappedVertical, mappedBuyers]);

  /**
   * handleSetStartDate
   * handleSetEndDate
   *
   * interpret local date as actual time in preferred timezone
   *
   * this means that if the selected date time is 01-01-2022 00:00:00 (in local timezone)
   * it should be equivalent to 01-01-2022 00:00:00 (in `timezone`)
   *
   */
  const handleSetStartDate = (date: Date | null) => {
    if (!date) return;

    if (date instanceof Date) {
      const theDate = dateToMomentTz(date, timezone);

      setStartDate(theDate);
      if (theDate > endDate) setEndDate(theDate.plus({ hours: 1 }));
    } else {
      return;
    }
  };
  const handleSetEndDate = (date: Date | null) => {
    if (!date) return;
    
    if (date instanceof Date) {
      const theDate = dateToMomentTz(date, timezone);

      if (theDate < startDate) setEndDate(startDate);
      else setEndDate(theDate);
    } else {
      return;
    }
  };

  const {
    Modal: ConfirmationModal,
    closeModal: closeConfirmation,
    useModal: openConfirmation,
  } = useConfirmation();

  const dateRangeCheck = () => {
    // const minDateExpected = DateTime.now().setZone(timezone).minus({ days: 5 });

    // const minDateExpected = endDate.minus({ days: 5 });
    
    /* if (startDate < minDateExpected.startOf("day") || endDate < minDateExpected.startOf("day")) {
      openConfirmation(
        () => {
          handleUpdateOpsScreenFilters();
        },
        {
          title: "Data Date Range Selection Notice",
          description:
            "The date range selected may be out of the range of our operational data. The results might not be accurate.",
          confirm: "I Understand",
          cancel: "Cancel / Revise",
        }
      );
    } else {
      handleUpdateOpsScreenFilters();
    } */

    handleUpdateOpsScreenFilters();
  };

  const TimeSelectionModeOptions: any = {  
    "today": "Today",
    "hour": "Past Hour",
    "hour_12": "Past 12 Hours",
    "hour_24": "Past 24 Hours",
    "yesterday": "Yesterday",
    "week": "Past Week",
    "week_work": "Week to date",
    "custom": "Custom",
  };

  const eventTracker = ({ opsScreenSearch }: any) => {
    const startDate = DateTime.fromISO(opsScreenSearch?.startDate);
    const endDate = DateTime.fromISO(opsScreenSearch?.endDate);
    const diff = endDate.diff(startDate, ['hours', 'minutes']);        
        
    const blockers: any = { '@': '[at]', '.': '[dot]' };
    tracker({
        name: "OpsScreenDateFilter",
        caption: "Track Ops Screen Date Filter",
        values: {
          TimeSelectionMode: TimeSelectionModeOptions[pastTime ?? "today"],
          ...diff.toObject(),           
          email: googleUserMeta?.email?.replace(/\@|\./g, it => blockers[it]) ?? null 
        } 
    });      
  };

  const resetTabs = () => {
    dispatch(
      updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus:  0,
          tabs: opsScreenTabs.tabContainers[TAB_CONTAINER].tabs.splice(0, 1),
        },
      })
    );
  };

  /** preload start and end date if set in opsScreenSearch */
  useEffect(() => {
    if (!!opsScreenSearch?.startDate) {
      const startDate = DateTime.fromFormat(
        opsScreenSearch?.startDate,
        LDPAPIDateFormat,
        { zone: "local" }
      ).toJSDate();
      handleSetStartDate(startDate);
    } 

    if (!!opsScreenSearch?.endDate) {
      setEndDate(
        dateToMomentTz(DateTime.fromFormat(opsScreenSearch?.endDate, LDPAPIDateFormat, {
          zone: "local",
        }).toJSDate(), timezone)
      );
    }
    
    // Event tracker fires off when opsScreenSearch detail changes.
    eventTracker({ opsScreenSearch });
  }, [opsScreenSearch]);

  //handle auto submits of past hour , past 12 hour and past 24 hour
  const [startDateChanged, setStartDateChanged] = useState(false);
  const [endDateChanged, setEndDateChanged] = useState(false);
  const [autoReloadData, setAutoReloadData] = useState(false);

  useEffect(() => {
    if (startDateChanged && endDateChanged && autoReloadData) {
      setStartDateChanged(false);
      setEndDateChanged(false);
      setAutoReloadData(false);

      dateRangeCheck();
    }
  }, [startDateChanged, endDateChanged, autoReloadData]);

  useEffect(() => {
    const fldTime = new Date(startDate.toISO({ includeOffset: false }));

    setStartDateChanged(true);
    setStartDateNative(fldTime);
  }, [startDate]);

  useEffect(() => {
    setEndDateChanged(true);
    setEndDateNative(new Date(endDate.toISO({ includeOffset: false })));
  }, [endDate]);

  const [pastTime, setPastTime] = useState<string>(opsScreenSearch?.timeSelectionMode || "hour");
  const [hideDates, setHideDates] = useState<boolean>(false);

  const handlePastTimeChange = (e: BaseSyntheticEvent) => {
    setPastTime(e.target.value);
    setDateRangeBySelection(e.target.value);
    resetTabs(); // since dataset is being updated, closed all oped tabs
  };

  // useEffect(() => {
  //   // set mode to today and refresh data 
  //   // if we came from another page
  //   if(localStorage.getItem("lastPage") !== "ops"){
  //     // raise indicator that we are on ops-screen
  //     // toogle marker opsScreenTabs.tsx @SAA-2369
  //     localStorage.setItem("lastPage", "ops");

  //     setPastTime('today');
  //     setDateRangeBySelection("today");
  //     resetTabs();
  //   }
  // }, []);

  const setDateRangeBySelection = (
    pastTime: string,
    forceReload: boolean = false
  ) => {
    const momentNow = DateTime.now().setZone(timezone);

    const _startDate = !!opsScreenSearch?.startDate
      ? DateTime.fromFormat(opsScreenSearch?.startDate, LDPAPIDateFormat, {
          zone: timezone,
        })
      : DateTime.now().setZone(timezone).minus({ hours: 1 });

    if (pastTime !== "custom") {
      setEndDate(momentNow);
    }

    let reload = true;
    let hideDateFields = true;

    switch (pastTime) {
      case "hour":
        setStartDate(momentNow.minus({ hours: 1 }));
        break;
      case "hour_12":
        setStartDate(momentNow.minus({ hours: 12 }));
        break;
      case "hour_24":
        setStartDate(momentNow.minus({ days: 1 }));
        break;
      case "yesterday":
        const yesterday = DateTime.now().minus({ days: 1 }).toMillis();
        setStartDate(DateTime.fromMillis(yesterday).setZone(timezone).startOf('day'));
        setEndDate(DateTime.fromMillis(yesterday).setZone(timezone).endOf('day'))
        break;
      case "week":
        setStartDate(momentNow.minus({ weeks: 1 }));
        break;
      case "week_work":
        setStartDate(momentNow.startOf("week"));
        break;
      case "custom":
        reload = forceReload;
        hideDateFields = false;

        setEndDate(
          !!opsScreenSearch?.endDate
            ? DateTime.fromFormat(opsScreenSearch?.endDate, LDPAPIDateFormat, {
                zone: timezone,
              })
            : momentNow
        );
        setStartDate(_startDate);
        if (forceReload) {
          setStartDateChanged(true);
          setEndDateChanged(true);
        }
        break;
      default:
        setStartDate(todayStart());
        setEndDate(todayEnd());
        break;
    }

    setAutoReloadData(reload);
    setHideDates(hideDateFields);
  };

  return (
    <Grid item xs={12}>
      <Box p={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={2}>
            <Autocomplete
              id="vertical-input"
              options={mappedVertical || []}
              getOptionLabel={(option) => option.label}
              value={selectedVertical}
              onChange={(event: any, newValue: MappedSelectField | null) => {
                setSelectedVertical(newValue);
              }}
              className={classes.input}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vertical"
                  variant="outlined"
                  inputRef={register()}
                  name="VerticalId"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Autocomplete
              id="subvertical-input"
              options={mappedSubVertical || []}
              getOptionLabel={(option) => option.label}
              value={selectedSubVertical}
              onChange={(event: any, newValue: MappedSelectField | null) => {
                setSelectedSubVertical(newValue);
              }}
              disabled={!selectedVertical}
              className={classes.input}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="SubVertical"
                  variant="outlined"
                  inputRef={register()}
                  name="SubVerticalId"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Autocomplete
              id="buyer-input"
              options={mappedBuyers || []}
              getOptionLabel={(option) => option.label}
              value={selectedBuyer}
              onChange={(event: any, newValue: MappedSelectField | null) => {
                setSelectedBuyer(newValue);
              }}
              className={classes.input}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buyer"
                  variant="outlined"
                  inputRef={register()}
                  name="BuyerId"
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            lg={2}
            style={{ display: hideDates ? "none" : "inherit" }}
          >
            <DatePicker
              id="start-date-input"
              name="StartDate"
              className={classes.input}
              dateFormat={"yyyy-MM-dd hh:mm:ss a"}
              onChange={(date) => {
                handleSetStartDate(date);
              }}
              showTimeSelect
              selected={startDateNative}
              customInput={
                <TextField
                  label={`Start Date (${timezone})`}
                  variant="outlined"
                  inputRef={register()}
                  name="StartDate"
                  fullWidth
                />
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={2}
            style={{ display: hideDates ? "none" : "inherit" }}
          >
            <DatePicker
              id="end-date-input"
              name="EndDate"
              className={classes.input}
              dateFormat={"yyyy-MM-dd hh:mm:ss a"}
              onChange={(date) => {
                handleSetEndDate(date);
              }}
              showTimeSelect
              allowSameDay
              maxDate={DateTime.now().endOf("day").toJSDate()}
              selected={endDateNative}
              customInput={
                <TextField
                  label={`End Date (${timezone})`}
                  variant="outlined"
                  inputRef={register()}
                  name="EndDate"
                  fullWidth
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={2}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              style={{ height: "100%" }}
              onClick={() => {
                if(pastTime !== 'custom'){
                  setDateRangeBySelection(pastTime, true);
                } else {
                  dateRangeCheck();
                }
                eventTracker({ opsScreenSearch });
                resetTabs(); // since dataset is being updated, closed all oped tabs
              }}
            >
              Update List
            </Button>
          </Grid>

          <Hidden smDown>
            <Grid
              item
              xs={12}
              sm={12}
              lg={8}
              style={{ display: hideDates ? "none" : "inherit" }}
            >
              &nbsp;
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={12} lg={2}>
            <Select
              native
              value={pastTime}
              onChange={handlePastTimeChange}
              inputProps={{
                name: "chrono",
                id: "outlined-selectable-time",
              }}
              variant="outlined"
              className={classes.input}
              style={{ width: "100%" }}
            >
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="hour">Past Hour</option>
              <option value="hour_12">Past 12 Hours</option>
              <option value="hour_24">Past 24 Hours</option>
              <option value="week">Past Week</option>
              <option value="week_work">Week to date</option>
              <option value="custom">Custom</option>
            </Select>
          </Grid>

          <Grid item xs={12} sm={12} lg={2}>
            <p
              style={{
                textAlign: "center",
                lineHeight: "30px",
                color: "#8a8a8a",
              }}
            >
              {opsScreenSearch?.lastUpdatedAt &&
                "Last updated at " + opsScreenSearch?.lastUpdatedAt}
            </p>
          </Grid>
        </Grid>
      </Box>

      <ConfirmationModal />
    </Grid>
  );
};

export default connect(
  (state: RootState) => ({
    opsScreenTabs: state.tabsSection,
    opsScreenSearch: state.opsScreenSearch,
  }),
  null
)(OpsScreenListFilters);
