import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import ListIcon from "@material-ui/icons/List";
import RefreshIcon from "@material-ui/icons/Refresh";
import SettingsIcon from "@material-ui/icons/Settings";
import TimelineIcon from "@material-ui/icons/Timeline";
import React, { Fragment, useEffect, useState } from "react";
import { GET_CONTRACTS } from "../../common/models/contracts";
import { GET_OPS_SCREEN_DETAILS } from "../../common/models/opsScreen";
import { GET_RESPONSE_DISPOSITION } from "../../common/models/responseDisposition";
import { GetContracts } from "../../common/models/types/GetContracts";
import { GetOpsScreenDetails } from "../../common/models/types/GetOpsScreenDetails";
import { GetResponseDisposition } from "../../common/models/types/GetResponseDisposition";
import {
  dateToPreferredTimezone,
  getTimezoneSettingLongDesc,
} from "../../common/utils/date";
import { findMuiRowValue } from "../../common/utils/findMuiRowValue";
import { LDPUIDataTable, queryLoad, useModal } from "../../components";
import { stringColumnFilter } from "../../components/customMuiDatatableFilter";
import LeadViewModal from "../leads/leadViewModal";
import { LdpJourneyDetails } from "./ldpJourneyDetails";
import OpsScreenDetailModal from "./opsScreenDetailModal";

interface IdValuePair {
  [id: string]: string;
}

interface VwPingStatsModalProps {
  buyer: string;
  startDate: string;
  endDate: string;
  accountManagerName: string;
  verticalId: number;
  subVerticalId: number;
  isSuccess: boolean | "both";
  reportType: "ping" | "post" | "pingfilter" | "postfilter";
  verticalNames: IdValuePair;
  subVerticalNames: IdValuePair;
  fetchEDW: boolean;
}

const useStyles = makeStyles(() => {
  return createStyles({
    tableContainer: {
      display: "flex",
    },
  });
});

export const VwPingStatsModalTab = ({
  buyer,
  isSuccess,
  startDate,
  endDate,
  accountManagerName,
  verticalId,
  subVerticalId,
  reportType,
  verticalNames,
  subVerticalNames,
  fetchEDW,
}: VwPingStatsModalProps) => {
  const classes = useStyles();
  const { Modal, closeModal, openModal, setContent } = useModal({}, 0.8);

  const [
    getVwPingStats,
    { data, called, error, loading, refetch },
  ] = useLazyQuery<GetOpsScreenDetails>(GET_OPS_SCREEN_DETAILS, {
    fetchPolicy: "cache-and-network",
  });

  const [
    getResponseDispositions,
    {
      data: dispositionData,
      called: dispositionCalled,
      error: dispositionError,
      loading: dispositionLoading,
      refetch: dispositionRefecth,
    },
  ] = useLazyQuery<GetResponseDisposition>(GET_RESPONSE_DISPOSITION);

  //Get contracts to map Contract ID to Contract Name
  const [
    getAllContracts,
    {
      data: contractData,
      called: contractCalled,
      error: contractError,
      loading: contractLoading,
      refetch: contractRefecth,
    },
  ] = useLazyQuery<GetContracts>(GET_CONTRACTS);

  const [pageLoading, setPageLoading] = useState(true);
  const [mappedVwPingStats, setMappedVwPingStats] = useState<any>([]);

  const pageTitle =
    `${buyer} ` +
    (isSuccess === "both"
      ? "Pings"
      : isSuccess
      ? "Accepted Pings"
      : "Rejected Pings");
  const tableId =
    `${buyer.toLowerCase().replaceAll(/s/g, "_")}-` +
    (isSuccess === "both"
      ? "Pings"
      : isSuccess
      ? "accepted-Pings"
      : "rejected-pings") +
    verticalId +
    "_" +
    subVerticalId;

  const onRefreshList = () => {
    let rptType =
      reportType === "ping" || reportType === "pingfilter"
        ? isSuccess === "both"
          ? "Pings"
          : isSuccess
          ? "PingsAccepted"
          : "PingsRejected"
        : isSuccess === "both"
        ? "Posts"
        : isSuccess
        ? "PostsAccepted"
        : "PostsRejectedRequestFilter";

    if (rptType === "PostsRejectedRequestFilter" && !!fetchEDW) {
      rptType = "PostsRejectedFilter";
    } else if (!!fetchEDW) {
      rptType = rptType.toLowerCase();
    }

    getVwPingStats({
      variables: {
        buyerName: buyer,
        startDate,
        endDate,
        accountManagerName,
        verticalId,
        subVerticalId,
        dispositionId:
          reportType === "pingfilter" || reportType === "postfilter"
            ? 35
            : undefined,
        reportType: rptType,
        fetchEDW: !!fetchEDW,
        timezone: getTimezoneSettingLongDesc(),
      },
    });
  };

  useEffect(() => {
    if (!called) {
      onRefreshList();
    }
  }, []);

  useEffect(() => {
    if (!!data && !error && !contractCalled) {
      getAllContracts();
    }
  }, [data, error]);

  useEffect(() => {
    if (
      !!data &&
      !error &&
      (contractData || contractError) &&
      !dispositionCalled
    ) {
      getResponseDispositions();
    }
  }, [contractData, contractError]);

  useEffect(() => {
    if (
      data &&
      (contractData || contractError) &&
      (dispositionData || dispositionError)
    ) {
      setMappedVwPingStats(
        data?.LDPIngestQueryGroup?.GetOpsScreenDetails?.map(
          (opsScreenDetail) => {
            return {
              ...opsScreenDetail,
              // ReceiveDatePST: dateToPreferredTimezone(opsScreenDetail?.ReceiveDate),
            };
          }
        )
      );
      setPageLoading(false);
    }
  }, [data, contractData, dispositionData]);

  const columnsMUI = [
    {
      name: "ContractId",
      label: " ",
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          const [ContractId, LeadDataId, ContractName] = findMuiRowValue(
            columnsMUI,
            ["ContractId", "LeadDataId", "ContractName"],
            tableMeta.rowData
          );
          return (
            <React.Fragment>
              <IconButton
                onClick={() => {
                  openModal({
                    title: `Lead Data ID #${LeadDataId} - ${ContractName}`,
                    icon: <SettingsIcon />,
                    iconColor: "green",
                    content: (
                      <OpsScreenDetailModal
                        params={{
                          ContractId,
                          LeadDataId,
                          VerticalId: verticalId,
                          SubVerticalId: subVerticalId,
                          IsPing:
                            reportType === "ping" || reportType === "pingfilter"
                              ? 1
                              : 0,
                          fetchEdw: !!fetchEDW,
                        }}
                        close={closeModal}
                      />
                    ),
                  });
                }}
              >
                <LaunchIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  openModal({
                    content: (
                      <LdpJourneyDetails
                        LeadDataId={LeadDataId}
                        VerticalId={verticalId}
                        SubVerticalId={subVerticalId}
                        FetchEDW={!!fetchEDW}
                      />
                    ),
                    title: `LDP Journey Lead Data Id #${LeadDataId} on Vertical ${verticalId}, Sub-Vertical ${subVerticalId}`,
                  });
                }}
              >
                <TimelineIcon />
              </IconButton>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: "ContractId",
      label: "Contract ID",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "SignupId",
      label: "Signup ID",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "LeadDataId",
      label: "LeadDataId",
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => (
          <Link
            href="#"
            onClick={() => {
              openModal({
                title: "Lead Data ID #" + value,
                icon: <ListIcon />,
                iconColor: "rose",
                content: (
                  <LeadViewModal
                    LeadDataId={value}
                    VerticalId={verticalId}
                    SubVerticalId={subVerticalId}
                    close={closeModal}
                    FetchEDW={fetchEDW}
                  />
                ),
              });
            }}
          >
            <Typography align={"left"} variant={"body2"}>
              {" "}
              <strong>{`${value}`}</strong>
            </Typography>
          </Link>
        ),
      },
    },
    {
      name: "ContractName",
      label: "Contract Name",
      options: {
        display: true,
        customBodyRender: (value: any, tableMeta: any) => (
          <Link href={`/contracts/${tableMeta.rowData[0]}`} target="_blank">
            <Typography align={"left"} variant={"body2"}>
              {" "}
              <strong>{value}</strong>
            </Typography>
          </Link>
        ),
      },
    },
    {
      name: "PriceOffered",
      label: "Price Offered",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "ReceiveDate",
      label: "Receive Date",
      options: {
        display: true,
        filter: false,
        sortCompare: (order: any) => {
          return (obj1: any, obj2: any) => {
            //   let val1 = DateTime.fromFormat(obj1.data, 'MMMM dd, yyyy hh:mm:ss a z').toMillis();
            //   let val2 = DateTime.fromFormat(obj2.data, 'MMMM dd, yyyy hh:mm:ss a z').toMillis();
            let val1 = new Date(obj1.data).getTime();
            let val2 = new Date(obj2.data).getTime();
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        customBodyRender: (value: any, tableMeta: any) => {
          // const date = DateTime.fromFormat(value, 'MMMM dd, yyyy hh:mm:ss a z');
          // if (! date || ! date.isValid)
          //    return 'null';

          // return date.year < 2000 ? 'null' : value;

          return dateToPreferredTimezone(value);
        },
      },
    },
    {
      name: "ResponseDispositionName",
      label: "Response Disposition Name",
      options: {
        display: true,
      },
    },
    {
      name: "ResponseStatusCode",
      label: "Response Status Code",
      options: {
        display: true,
        ...stringColumnFilter,
      },
    },
    {
      name: "ClientTimeMilliSeconds",
      label: "Client Time Milliseconds",
      options: {
        display: true,
        filter: false,
      },
    },
    /* {
         name: "SignupId",
         label: "Signup ID",
         options: {
            "display": true,
         }
      },
      {
         name: "UUID",
         label: "UUID",
         options: {
            "display": false,
         }
      }, */
  ];

  return (
    <Fragment>
      <Box p={1}>
        <Grid container spacing={1} justify="flex-end">
          <Grid item sm={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              startIcon={<RefreshIcon />}
              onClick={() => {
                refetch();
              }}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {queryLoad([!!loading, !!pageLoading], [error]) || (
          <LDPUIDataTable
            ldpTableId={tableId}
            title={!!loading && <CircularProgress size={20} />}
            data={mappedVwPingStats}
            columns={columnsMUI}
            options={{
              selectableRows: "none",
              selectableRowsHeader: false,
              selectableRowsHideCheckboxes: true,
              selectableRowsOnClick: false,
              responsive: "vertical",
              fixedHeader: true,
              filterType: "checkbox",
              sortOrder: {
                name: "ReceiveDate",
                direction: "desc",
              },
              rowsPerPage: 25,
              tableBodyMaxHeight: "calc(100vh - 340px)",
            }}
          />
        )}
      </Box>
      <Modal />
    </Fragment>
  );
};
