export const sortInt = (order:string) => {
  return (obj1:any, obj2:any) => {
    
    // console.log(order, obj1.data, obj2.data);

    const val1 = parseInt(`${obj1.data || 0}`.replaceAll(',', ''), 10);
    const val2 = parseInt(`${obj2.data || 0}`.replaceAll(',', ''), 10);

    return (val1 - val2) * (order === 'asc' ? 1 : -1);
  };
}

export const sortNumber = (order:string) => {
  return (obj1:any, obj2:any) => {
    
    // console.log(order, obj1.data, obj2.data);

    const val1 = Number(`${obj1.data || 0}`.replaceAll(',', ''));
    const val2 = Number(`${obj2.data || 0}`.replaceAll(',', ''));

    return (val1 - val2) * (order === 'asc' ? 1 : -1);
  };
}