import { useQuery } from "@apollo/react-hooks";
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { ApolloError } from "apollo-client";
import React, { useEffect, useState } from "react";
import { GET_OPS_SCREEN_DETAILS } from "../../common/models/opsScreen";
import {
  GetOpsScreenDetails,
  GetOpsScreenDetails_LDPIngestQueryGroup_GetOpsScreenDetails,
} from "../../common/models/types/GetOpsScreenDetails";
import { GetOpsScreenDispositionsVariables } from "../../common/models/types/GetOpsScreenDispositions";
import { getTimezoneSettingLongDesc } from "../../common/utils/date";
import { LDPUIDataTable, queryLoad } from "../../components";

interface OpsScreenCapacityProps {
  params: GetOpsScreenDispositionsVariables;
  fetchEDW: boolean;
}

interface CapacityDisposistion {
  ResponseDispositionName?: string | null;
  Volume: number;
}

export const OpsScreenCapacity = ({
  params,
  fetchEDW,
}: OpsScreenCapacityProps) => {
  const {
    data: opsScreenDetailsData,
    loading: opsScreenDetailsLoading,
    error: opsScreenDetailsError,
  } = useQuery<GetOpsScreenDetails>(GET_OPS_SCREEN_DETAILS, {
    variables: {
      startDate: params.startDate,
      endDate: params.endDate,
      accountManagerName: params.accountManagerName,
      buyerName: params.buyerName,
      verticalId: params.verticalId,
      subVerticalId: params.subVerticalId,
      reportType: fetchEDW
        ? `${params.isPost ? "Posts" : "Pings"}Capacity${
            params.isSuccess ? "Accepted" : "Rejected"
          }`.toLowerCase()
        : `${params.isPost ? "Posts" : "Pings"}Capacity${
            params.isSuccess ? "Accepted" : "Rejected"
          }`,
      fetchEDW: fetchEDW,
      timezone: getTimezoneSettingLongDesc(),
    },
    fetchPolicy: "cache-and-network",
  });

  const [finalData, setFinalData] = useState<CapacityDisposistion[]>([]);

  const classes = useStyles();

  useEffect(() => {
    if (opsScreenDetailsData?.LDPIngestQueryGroup?.GetOpsScreenDetails) {
      const finalData: CapacityDisposistion[] = [];
      opsScreenDetailsData?.LDPIngestQueryGroup?.GetOpsScreenDetails.forEach(
        (item) => {
          const found = finalData.findIndex(
            (el) => el.ResponseDispositionName === item?.ResponseDispositionName
          );
          if (found < 0)
            finalData.push({
              ResponseDispositionName: item?.ResponseDispositionName,
              Volume: 1,
            });
          else finalData[found].Volume++;
        }
      );

      setFinalData(finalData);
    }
  }, [opsScreenDetailsData]);

  const columns = [
    {
      name: "ResponseDispositionName",
      label: "Response Disposition Name",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    {
      name: "Volume",
      label: "Volume",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
  ];

  const options: any = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPage: 25,
  };

  return (
    <Paper className={classes.container}>
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.tableWrapper}>
          {queryLoad([!!opsScreenDetailsLoading], [opsScreenDetailsError]) || (
            <LDPUIDataTable
              ldpTableId="ops-screen-disposition"
              restoreFilters={true}
              title={<Typography variant="h6">&nbsp;</Typography>}
              data={finalData}
              columns={columns}
              options={options}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

interface OpsScreenDetailListProps {
  data: GetOpsScreenDetails_LDPIngestQueryGroup_GetOpsScreenDetails;
  loading: Boolean;
  error: ApolloError | undefined;
  verticalId: string;
  subVerticalId: string;
  isPing: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: "left",
      position: "relative",
      minHeight: "300px",
      boxShadow: "none",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    tableWrapper: {
      margin: "4px",
      overflowY: "auto",
    },
    CodeWrapper: {
      maxHeight: "120px",
      width: "240px",
      overflowY: "auto",
      overflowX: "hidden",
      padding: "5px",
      fontFamily: "monospace",
      boxShadow: "none",
      fontSize: "0.87rem",
    },
    jsonPrettyContainer: {
      position: "relative",
      height: "150px",
      "&:hover": {
        cursor: "pointer",
        "& $jsonPrettyOverlay": {
          display: "block",
        },
      },
    },
    jsonPrettyOverlay: {
      position: "absolute",
      display: "none",
      backgroundColor: "rgb(0 0 0 / 60%)",
      width: "100%",
      height: "100%",
      zIndex: 2,
    },
    jsonPrettyBtn: {
      margin: "50px auto",
      display: "block",
    },
  })
);
